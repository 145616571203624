@media screen and (min-width: 800px) {

  .txt-18 {
    font-size: 18px;
  }

  .txt-20 {
    font-size: 20px;
    font-weight: 300;
  }

  .txt-24 {
    font-size: 24px;
  }

  .txt-26 {
    font-size: 26px;
  }

  .txt-28 {
    font-size: 28px;
  }

  .txt-32 {
    font-size: 32px;
  }

  .txt-36 {
    font-size: 36px;
  }

  .txt-80 {
    font-size: 80px;
    font-weight: bold;
    letter-spacing: 5px;
  }

  .txt-100 {
    font-size: 100px;
  }

  .title-txt50 {
    font-size: 50px;
    font-weight: bold;
  }

  .ant-table-thead>tr>th {
    text-align: center !important;
  }

  //////////////////////////////////////////////    메인    ////////////////////////////////////////////////
  .header-container {
    width: 100%;
    margin: 0 auto;
  }

  .top-navi {
    margin: 0 auto;
    width: 800px;
    // padding-top: 20px;
    text-align: center;
  }

  .top-navi>div {
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 24px;

  }

  .top-menu {
    color: #f2f8f6;
    font-weight: bold;
    padding: 20px 40px;
    cursor: pointer;
  }

  .top-menu.active {
    color: #000;
    font-weight: bold;
    cursor: pointer;
    background-color: #f2f8f6;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    // border-radius: 20px;

  }

  .top-text {
    width: 100px;
  }

  .blue {
    color: #065cc7;
  }

  .color-blue {
    border-color: #2aa5d0;
    color: #2aa5d0;
  }

  .BT-HD-right-container {
    padding-top: 70px;
    padding-right: 20px;
    max-width: 500px;
    text-align: right;
    margin: 0px auto;
    font-size: 18px;
    color: #fff;
    font-weight: bold;
  }

  .BT-HD-right-element {
    display: inline-block;
    margin-left: 10px;
    cursor: pointer;
  }

  .ant-form {}

  .ant-input {}

  .ant-form-item-explain {
    padding-bottom: 10px !important;
  }

  .signup-form {}

  .signup-btn {
    width: 100% !important;
    height: 50px !important;
    border-width: 0px !important;
    color: #fff !important;
    font-size: 22px !important;
    font-family: NotoSansRegular !important;
  }

  .signup-btn-ok {
    float: left;
    background-color: #0641c0 !important;
    margin-left: px;
    cursor: pointer;
  }

  .signup-btn-cancel {
    float: right;
    background-color: #575757 !important;
    cursor: pointer;
  }

  .signup-btn:focus {
    outline: none;
  }

  .resend-btn-ok {
    float: left;
    background-color: #999999 !important;
    margin-left: px;
    cursor: pointer;
  }

  .form {
    color: red;
    font-size: 14px;
    text-align: center;
    padding: 30px 0px;
    margin: 5px;
  }

  .form2 {
    color: black;
    font-size: 16px;
    text-align: right;
    height: 40px;
    float: right;
    margin-top: 20px;
  }

  .form3 {
    float: left;

  }

  body {
    background-color: #f2f8f6 !important;
  }

  .wrapper {
    background-image: url('../img/join_bg.png');
    background-repeat: no-repeat;
    background-size: 115% 100%;


  }

  .mining-bg {
    background-image: url('../img/mining/mining-bg.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    text-align: center;
  }


  .header {
    width: 96%;
    height: 200px;
    max-width: 500px;
    margin: -70px auto 0px auto;
    background-image: url('../img/logo/logo.png');
    background-repeat: no-repeat;
    background-position-x: left;
    background-position-y: 0px;
    padding-top: 150px;
    font-size: 24px;
    color: #fff;
    text-align: center;
  }

  .container {
    // border: 2px solid blue;
    // padding: 100px 0;

  }

  .container-box {
    width: 800px;
    padding: 50px;
    background-color: #f2f8f6;
    // border: 4px solid #065cc7;
    box-shadow: 0 15px 15px 0 #065cc7;
    border-radius: 20px;
    // max-width: 1000px;
    margin: 0 auto;
    min-height: 700px;
  }

  .reco {
    font-size: 16px;
    font-weight: bold;
  }

  .reco-code {
    color: #0641c0;
  }

  .input2 {

    height: 40px;
    margin-bottom: 15px;

    font-size: 16px;

  }

  .input22 {
    height: 40px;
    margin-bottom: 15px;
    margin-left: 0px;
    font-size: 16px;

  }

  .login-field-icon {
    float: left;
    padding-right: 10px;

    &>img {}
  }

  .login-form-wrapper {
    padding-left: 0px;
    position: relative;
  }

  .login-field-input {
    text-align: left;

    & input {
      height: 40px;
      text-indent: 10px;
    }
  }

  img {
    width: 38px;

  }

  .overlap-button {
    border: 1px solid #0641c0;
    border-radius: 5px;
    color: #0641c0;
    padding: 4px 20px;
    margin: 4px 5px;
    cursor: pointer;
    position: absolute;
    z-index: 10;
    right: 5px;
    background-color: #fff;
  }

  input {
    border: none;
    border-bottom: 1px solid #999999;
    margin: 3px;

  }

  input:focus {
    outline: none;
  }

  .agree {
    text-align: left;
    margin-top: 20px;
  }

  .agree-title {
    font-size: 16px;
    color: #000;
    font-weight: bold;
  }

  .agree-content {
    font-size: 16px;
    color: #666666;
    padding-bottom: 10px;
  }

  .agree-link {
    float: right;
    margin: 10px 0px 0px 0px;
    cursor: pointer;
  }

  .agree .ant-form-item-explain {
    padding-top: 10px;
  }

  .agreement-content {
    height: 300px;
    overflow: auto;
  }

  .agreement-content-title {
    font-size: 16px;
    color: '#2d67ff';
    text-decoration: underline;
    font-weight: bold;
    padding: 10px 0px;
  }

  .agreement-content-body {
    font-size: 12px;

  }

  .chkbox {
    margin-top: 20px;
  }

  .signupbtn {
    margin-top: 30px;
    height: 100px;
  }

  h2 {
    color: black;
    font-size: 28px;
    margin-left: 30px;
  }

  .logo {
    width: 70px;
  }

  button {
    margin-bottom: 5px;

  }

  .signupok {
    margin-top: 10px;
  }

  .signupok img {
    width: 120px;
  }

  .signupok-text {
    padding: 50px 0px;
    font-size: 16px;
  }

  .signupok-text-bold {
    color: #0641c0;
    font-weight: bold;
  }

  .signupok-button {
    background-color: #0641c0;
    font-size: 18px;
    color: #fff;
    padding: 10px 20px;
    cursor: pointer;
    width: 200px;
  }

  .currencyNotice {
    margin-top: 20px;

    tr {
      background-color: rgba(6, 64, 200, 0.1);
    }

    th {
      display: none;

    }
  }


  .blockchain-wrapper {
    text-align: left;
    background-color: rgba(6, 64, 200, 0.1);
    margin: 20px 0px;
    padding: 20px;
    // border: #999999 1px solid;
    border-radius: 15px;
    height: auto;

  }

  .blockchain-image {
    display: inline-block;
    padding: 10px;
    vertical-align: top;
  }

  .blockchain-image img {
    height: 38px;
    width: 38px;
  }

  .blockchain-content {
    width: 100%;
    display: inline-block;
  }

  .blockchain-content-name {
    padding: 12px;
    display: inline-block;
    font-size: 18px;
    color: #0641c0;
    font-family: NotoSansMedium;

  }

  .blockchain-content-balance {
    display: inline-block;
    text-align: right;
    // width: 200px;
    float: right;
    font-size: 18px;
    color: #000;
    font-family: NotoSansBold;

  }

  .blockchain-content2 {
    // width: 90%;
    float: right;
    flex-direction: row;
    text-align: right;
    display: inline-block;
  }


  .blockchain-content-balance span {
    display: inline-block;
    text-align: right;
    font-size: 16px;
    color: #000;
    font-family: NotoSansMedium;
    padding-left: 10px;
  }

  .blockchain-graph {
    margin-top: 50px;

  }

  .blockchain-content-balance2 {
    display: inline-block;
    width: 100%;
    text-align: right;
    font-size: 14px;
    color: #000;
    font-family: NotoSansMedium;
  }


  .signup-link {
    font-size: 18px;
    color: #000;
    font-family: NotoSansRegular;
    text-align: right;
    cursor: pointer;
    margin-top: -20px;
  }

  .password-modify-title {
    font-size: 18px;
    color: #0641c0;
    font-family: NotoSansMedium;
    padding: 20px 0px;

  }


  .main-box {
    margin-bottom: 20px;
  }

  .main-title {
    display: inline-block;
    width: 100%;
  }

  .main-text {
    font-size: 20px;
    font-weight: bold;
    float: left;
    display: inline-block;
    color: #065cc7;
  }

  .more-btn {
    display: inline-block;
    float: right;
  }


  .more-btn>a>button {
    border-radius: 10px;
    width: 100px;
    height: 40px;
    font-weight: 'bold';
  }

  .more-btn2 {
    display: inline-block;
    float: right;
    margin-left: 10px;
  }

  .more-btn2>a>button {
    display: inline-block;
    float: right;
    border-radius: 10px;
    width: 100px;
    height: 40px;
  }

  .back-btn {
    float: left;
    margin-bottom: 10px;
  }


  .sub-text {
    font-size: 14px;
    float: right;
    padding: 10px 10px 0 0;
  }

  .noticeTable tr {
    background-color: #fff;
  }

  .noticeTable tr:hover {
    background-color: rgba(6, 64, 200, 0.1);
  }

  .notice-title {
    font-family: NotoSansRegular !important;
  }

  .notice-title2 {
    font-family: NotoSansRegular !important;
    font-weight: bold;
    font-size: 16px;
    width: 100%;
  }


  .noticeTag {
    font-weight: bold;
    font-size: 18px;
  }

  .noticeTag1 {
    border-radius: 10px;
    border: 1px solid red;
    width: 60px;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
    color: red;
  }


  .noticeTag2 {
    border-radius: 10px;
    border: 1px solid #065cc7;
    width: 60px;
    text-align: center;
    font-size: 14px;
    margin-top: 8px;
    color: #065cc7;
  }

  .noticeContent {
    padding-top: 30px;
    font-size: 14px;
  }


  .charge-inner {
    background-color: #fff;
    padding: 40px 0;
  }

  .charge-inner2 {
    border-radius: 20px;
    border: 1px #9ea5ff solid;
    background-color: #f2f3ff;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 0 20px;
    padding: 20px;
  }

  .content-box {
    padding: 10px 0;
    font-weight: bold;
    font-size: 20px;
  }


  .wallet-bg {
    background-image: url('../img/backimage.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    height: 200px;
    padding-top: 20px;

  }

  .wallet-title {
    background-image: url('../img/mywallet_logo.png');
    background-repeat: no-repeat;
    width: 400px;
    height: 100px;
    background-size: 100% 80%;
    margin: 0 auto;
    // border: 1px solid red;


  }

  .wallet-logo {
    background-image: url('../img/btn_coin.png');
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 36px;
    height: 36px;
    display: inline-block;
    float: left;
    margin: 7px 15px;
  }

  .wallet-titbox {
    border: 1px solid #fff;
    border-radius: 30px;
    background-color: rgba(255, 255, 255, 0.2);
    width: 400px;
    height: 50px;
    margin: 0 auto;
    color: #fff;
    font-size: 24px;
    line-height: 50px;
  }

  .trade-list {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    background-color: #fff;
    margin-top: 10px;
    padding: 15px;
    border-radius: 20px;

    & div:nth-child(2) {
      letter-spacing: -1.2px;
      font-size: 12px;
      width: 45%;
    }

    & div:nth-child(3) {
      color: gray;
    }

  }

  .trade-nolist {
    margin-top: 10px;
    background-color: #fff;
    padding: 15px;
  }

  .trade-pagination {
    margin-top: 10px;
    // float: right;
    text-align: right;
  }

  .wallet-list-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.56);
    width: 100vw;
    height: 100vh;
    z-index: 100;
  }


  .wallet-list-wrapper {
    width: 650px;
    background-color: #fff;
    padding: 1.8rem;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 101;
  }

  .wallet-list-img-checked {
    -webkit-filter: opacity(.8) drop-shadow(0 0 0 #065cc7);
    filter: opacity(.8) drop-shadow(0 0 0 #065cc7);
  }

  .wallet-list-img-unchecked {
    -webkit-filter: opacity(.5) drop-shadow(0 0 0 #CCCCCC);
    filter: opacity(.5) drop-shadow(0 0 0 #CCCCCC);
  }

  .trade-send-confirm-modal {
    font-size: 18px;
    font-family: NotoSansRegular;
    letter-spacing: -1px;
  }

  .trade-send-confirm-title {
    font-size: 20px;
    font-family: NotoSansRegular;
    letter-spacing: -1px;
    margin: 20px 0px;
  }

  .trade-send-confirm-item {
    display: inline-block;
    line-height: 30px;
    font-size: 16px;

  }

  .send-btn>button {
    width: 94%;
    margin: 30px 20px 0;
    border-radius: 20px;
    padding: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    background-color: #5663ff;
    color: #fff;
    font-size: 16px;
  }

  .myinfo-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .myinfo-wrap.sub{
    margin-top: 14px;
  }

  .myinfo-subTitle {
    font-size: 18px;
    padding-left: 24px;
    word-break: keep-all;
  }
  .myinfo-obj{
    font-size: 18px;
    font-weight: bold;
    padding-right: 24px;
  }
  .myinfo-wrap .myinfoBtn,
  .myinfo-otp .myinfoBtn{
    border-radius: 10px;
    width: 100px;
    height: 40px;
    font-weight: 'bold';
  }
  .myinfoBtn.sub{
    margin-right: 24px;
  }
  .myinfoBtn.saveBtn{
    margin-left: 10px;
    background-color: #222;
    color:#fff;
    font-weight: bold;
  }
  .myinfo-wrap .myinfo-input {
    width: 70%;
    background-color: rgba(255,255,255,0.8);    
    border:none;        
    text-align: right;
    font-size: 18px;
  }
  .myinfo-otp{
    flex: 1;
    text-align: center;
    padding: 20px;
  }
  .ant-divider-vertical.dividerVertical{
    height: 20rem;
  }
  .myinfo-otp-box{
    height: 250px;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .myinfo-wrap .myinfo-input2 {
    width: 100%;
    background-color: rgba(255,255,255,0.8);    
    border:none;        
    text-align: center;
    font-size: 18px;
  }
  .mailIcon{
    font-size: 40px;
    margin-bottom: 12px;
  }
  .myinfo-otykey{
    margin: 6px 0;;
  }
  .myinfo-inputBox{
    width: 70%;
    display: flex;
    align-items: center;
  }
  .myinfo-inputBox > Button {
    width: 160px;
    height: 38px;
    background-color: #fff;
    margin-left: 6px;
    border: 1px solid #e0e0e0;
  }
  .myinfo-wrap .myinfo-input3 {
    width: 100%;
    background-color: rgba(255,255,255,0.8);    
    border:none;        
    text-align: right;
    font-size: 18px;
  }
  .myinfo-input.file{
    width: 90%;
    margin: 20px 0;
  }
  .myinfo-fileTitle{
    font-size: 18px;
    text-align: center;
    width: 80%;
    margin: 0 auto;
  }
  .myinfo-kycStatus{
    width:100px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
  }
}